/* eslint-disable no-console */
const init = (options) => {
  const {
    menuItems,
    menuItemSelector,
    onMenuHoverUpdate,
    onMenuClickUpdate,
  } = options;

  const closeAllMenus = () => {
    for (let i = 0; i < menuItems.length; i++) { /* eslint-disable-line no-plusplus */
      menuItems[i].classList.remove('is-active');
      menuItems[i].querySelector('button').setAttribute('aria-expanded', 'false');
    }
  };

  const onMenuItemClick = (event) => {
    const menuItem = event.target.closest(menuItemSelector);
    const btn = menuItem.querySelector('button');
    if (menuItem.classList.contains('is-active')) {
      menuItem.classList.remove('is-active');
      btn.setAttribute('aria-expanded', 'false');
    } else {
      closeAllMenus();
      menuItem.classList.add('is-active');
      btn.setAttribute('aria-expanded', 'true');
    }
    if (onMenuClickUpdate) {
      onMenuClickUpdate({
        activeMenuItem: menuItem,
      });
    }
  };

  const onMenuItemMouseover = (event) => {
    const menuItem = event.target.closest(menuItemSelector);
    if (menuItem === null) {
      return;
    }
    if (onMenuHoverUpdate) {
      onMenuHoverUpdate({
        activeMenuItem: menuItem,
      });
    }
  };

  [].map.call(menuItems, (menuItem) => {
    const btn = menuItem.querySelector('button');
    btn.addEventListener('click', onMenuItemClick);
    btn.parentNode.addEventListener('mouseover', onMenuItemMouseover);
  });
};

const update = () => {
  //
};

export {
  init,
  update,
};
/* eslint-enable no-console */
