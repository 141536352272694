/* eslint-disable no-console */
// https://codepen.io/georgedoescode/pen/oNzamjV
import { spline } from '@georgedoescode/spline';
import SimplexNoise from 'simplex-noise';

const shapeCoords = [
  [5.7, 116.6],
  [147, 0],
  [475, 26.4],
  [722.2, 9.2],
  [856.3, 125.6],
  [843.1, 406.9],
  [859, 784.4],
  [774.1, 964.6],
  [483.3, 956.7],
  [136, 973.5],
  [0, 817],
  [11.2, 426],
];

const createPoints = () => {
  const arr = [];
  shapeCoords.forEach((xy) => {
    const x = xy[0];
    const y = xy[1];
    arr.push({
      x,
      y,
      originX: x,
      originY: y,
      noiseOffsetX: Math.random() * 1000,
      noiseOffsetY: Math.random() * 1000,
    });
  });
  return arr;
};

const simplex = new SimplexNoise();
const points = createPoints();
const bgShapeSelector = document.querySelector('.deep-sea-blob');
const path = bgShapeSelector.querySelector('path');
const noiseStep = 0.01;
let isPaused = true;

const map = (n, start1, end1, start2, end2) => (
  ((n - start1) / (end1 - start1)) * (end2 - start2) + start2 /* eslint-disable-line no-mixed-operators */
);

const noise = (x, y) => (
  simplex.noise2D(x, y)
);

const render = () => {
  path.setAttribute('d', spline(points, 1, true));
  points.forEach((point) => {
    // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
    const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
    const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
    // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
    const x = map(nX, -1, 1, point.originX - 10, point.originX + 10);
    const y = map(nY, -1, 1, point.originY - 10, point.originY + 10);
    // update the point's current coordinates
    point.x = x;
    point.y = y;
    // progress the point's x, y values through "time"
    point.noiseOffsetX += noiseStep;
    point.noiseOffsetY += noiseStep;
  });
  // window.requestAnimationFrame(animate);
};

const init = () => {
  /* const {
    wrapperDiv,
  } = options; */
  // First paint
  render();
  // Update on interval
  setInterval(() => {
    if (!isPaused) {
      render();
    }
  }, 50);

  /* wrapperDiv.addEventListener('mouseover', () => {
    noiseStep = 0.02;
  });

  wrapperDiv.addEventListener('mouseleave', () => {
    noiseStep = 0.01;
  }); */
};

const udpate = (options) => {
  const {
    pause,
  } = options;
  isPaused = !pause;
  // console.log(isPaused);
};

export {
  init,
  udpate,
};
/* eslint-enable no-console */
