/* eslint-disable no-console */
import Glide from '@glidejs/glide';

export default class Slider {
  constructor(options) {
    const {
      breakpoints,
      disableAt,
      perView,
      selector,
    } = options;
    this.breakpoints = breakpoints;
    this.disableAt = disableAt;
    this.perView = perView;
    this.selector = selector;
    this.enabled = false;
    this.glide = null;
    window.addEventListener('resize', this.resize.bind(this));
    // Mount slider
    if (!this.disableAt) {
      this.init();
    } else {
      this.resize();
    }
  }

  init() {
    if (this.glide) {
      this.destroy();
    }
    this.glide = new Glide(this.selector, {
      breakpoints: this.breakpoints,
      perView: this.perView,
      startAt: 0,
      type: 'carousel',
    });
    this.glide.mount();
  }

  destroy() {
    if (this.glide) {
      this.glide.destroy();
      this.glide = null;
      setTimeout(() => {
        // Strip out any inline styles that were added to <li>
        const sliderItems = document.querySelectorAll(`${this.selector} li`);
        for (let i = 0; i < sliderItems.length; i++) { /* eslint-disable-line no-plusplus */
          sliderItems[i].removeAttribute('style');
        }
      }, 100);
    }
  }

  resize() {
    if (!this.disableAt) {
      return;
    }
    if (window.innerWidth <= this.disableAt && !this.enabled) {
      this.enabled = true;
      this.init();
    } else if (window.innerWidth > this.disableAt && this.enabled) {
      this.enabled = false;
      this.destroy();
    }
  }
}
/* eslint-enable no-console */
