/* eslint-disable no-console, no-unused-vars */
/*
  TODOS:
  [ ] Disable some animations on small screens?
*/
// http://lc-dev-page.com.s3-website-us-east-1.amazonaws.com/
import * as capabilities from './modules/capabilities/index';
import * as intro from './modules/intro';
import backgroundLoader from './modules/loaders/background-loader';
import intersectionObservers from './modules/intersection-observers';
// import Lazyload from './modules/lazyload';
import menu from './modules/menu';
import Slider from './modules/slider';

capabilities.init();
intro.init();
// Lazyload();
menu();

const teamSlider = new Slider({
  breakpoints: {
    767: {
      perView: 2,
      focusAt: 0,
    },
    519: {
      perView: 1,
      focusAt: 0,
    },
  },
  disableAt: 767,
  perView: 3,
  selector: '#legendary-slider',
});

const workSlider = new Slider({
  breakpoints: {
    991: {
      perView: 3,
      focusAt: 0,
    },
    767: {
      perView: 2,
      focusAt: 0,
    },
    575: {
      perView: 1,
      focusAt: 0,
    },
  },
  perView: 3,
  selector: '#work-slider',
});

const sections = document.querySelectorAll('[data-section]');

const isInFocus = section => (
  document.querySelector(`[data-section="${section}"].in-focus`) !== null
);

const manageFocus = () => {
  const {
    innerHeight,
    scrollY,
  } = window;
  const tolerance = innerHeight * 0.1;
  const offset = innerHeight - tolerance;
  for (let i = 0; i < sections.length; i++) { /* eslint-disable-line no-plusplus */
    const section = sections[i];
    const top = Math.max(section.offsetTop - offset, 0);
    const btm = (section.offsetTop + section.offsetHeight) - offset;
    if (scrollY >= top && scrollY < btm) {
      section.classList.add('in-focus');
    } else {
      section.classList.remove('in-focus');
    }
  }
  intro.update({
    active: isInFocus('intro') || isInFocus('what-we-do'),
  });
  capabilities.update({
    active: isInFocus('what-were-known-for'),
  });
};

setInterval(() => {
  manageFocus();
}, 100);

window.addEventListener('load', () => {
  setTimeout(() => {
    const body = document.querySelector('body');
    body.classList.add('site-loaded');
    intro.resize();
    backgroundLoader();
    intersectionObservers();
  }, 100);
});
/* eslint-enable no-console, no-unused-vars */
