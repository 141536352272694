/* eslint-disable no-console */
import waves from 'nice-waves';

let skyAnimation = null;
const introDiv = document.querySelector('.intro');
const introContentDiv = introDiv.querySelector('.intro-content-wrapper');

const resize = () => {
  const contentHeight = introContentDiv.offsetHeight;
  const {
    innerHeight,
  } = window;
  introDiv.style.minHeight = `${contentHeight}px`;
  // If content height (+ padding) is less than screen height, add centering class
  if (contentHeight < innerHeight) {
    introDiv.classList.add('center-align-content');
  } else {
    introDiv.classList.remove('center-align-content');
  }
};
const init = () => {
  const options = {
    fills: [
      'rgba(46, 88, 128, 0.85)',
      'rgba(32, 116, 142, 0.75)',
      'rgba(20, 139, 153, 0.65)',
      'rgba(7, 162, 164, 0.6)',
      'rgba(39, 188, 183, 0.5)',
      'rgba(80, 201, 197, 0.5)',
      'rgba(101, 208, 204, 0.5)',
      'rgb(217, 245, 242)',
    ],
    flowRate: 1,
    wavelength: 8,
    complexity: 4,
    offset: 0.5,
    randomWavelength: 0.05,
    randomOffset: 0.1,
  };
  skyAnimation = waves(options).mount('#cloud-waves').stop();

  // Add .fill-viewport to .intro
  introDiv.classList.add('fill-viewport');
  if (introDiv.classList.contains('fill-viewport')) {
    window.addEventListener('resize', resize);
  }
};

const update = (options) => {
  const {
    active,
  } = options;
  if (active) {
    skyAnimation.play();
  } else {
    skyAnimation.stop();
  }
};

export {
  init,
  resize,
  update,
};
/* eslint-enable no-console */
